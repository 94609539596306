import * as React from "react"

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import * as styles from "./bigSlider.module.scss"

// import required modules
import { Pagination,Navigation } from "swiper";
import {StaticImage} from "gatsby-plugin-image";
import VideoFrame from "../videoFrame/videoFrame";

import evroneOnboarding from '../../images/video_covers/evrone-ob.jpg'
import whatIsEvrone from '../../images/video_covers/evrone-wi.jpg'

const BigSlider = ()=> {
  return (
    <div className={styles.wrapBigSlide}>
      <Swiper
        autoHeight={true}
        pagination={{
          type: "fraction",
        }}
        navigation={true}
        modules={[Pagination,Navigation]}
        className={styles.bigSlider}>
        <SwiperSlide>

          <div className={styles.captionSlide}>Evrone Onboarding</div>
          <div>
            <VideoFrame isShowIcon={true} lightUrl={evroneOnboarding} videoUrl={'https://www.youtube.com/embed/eKKi0gVHRP8'}/>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.captionSlide}>What is Evrone?</div>
          <div>
            <VideoFrame isShowIcon={true} lightUrl={whatIsEvrone} videoUrl={'https://www.youtube.com/embed/7Fgzo3yjipA'}/>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default BigSlider