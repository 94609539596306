import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/page.scss"

import pict1 from '../images/pictures/pict-1.svg'
import pict2 from '../images/pictures/pict-2.svg'
import pict3 from '../images/pictures/pict-3.svg'
import pict4 from '../images/pictures/pict-4.svg'
import pict5 from '../images/pictures/pict-5.svg'
import pict6 from '../images/pictures/pict-6.svg'
import pict7 from '../images/pictures/pict-7.svg'
import pict8 from '../images/pictures/pict-8.svg'
import c2019 from '../images/video_covers/evrone19.svg'
import c2020 from '../images/video_covers/evrone20.svg'

import {graphql, useStaticQuery} from "gatsby";
import {Card, Cards} from "../components/cards/cards";
import BigSlider from "../components/bigSlider/bigSlider";
import MiddleSlider from "../components/middleSlider/middleSlider";


const IndexPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            version
          }
        }
      }
    `
  )

  if (site.siteMetadata.version === 'ru') {
    return (
      <Layout>
        <Seo
          lang={'ru'}
          title="Evrone ищет таланты!"
          description={"Ищем специалистов в разных областях, присоединяйтесь!"}
        />

        <div className={"block"}>
          <h1 className="textWrapper tW_bg text-t1">
            Команда Evrone занимается продуктовой разработкой стартапов и помогает в цифровой трансформации бизнеса в России, США и Европе.
          </h1>

          <div className={"textWrapper"}>
            <BigSlider/>
          </div>

          <div className={"textWrapper tW_bg text-t2"}>
            Мы занимаемся высоконагруженными, технологичными, наукоёмкими проектами с обширной бизнес логикой и большой серверной инфраструктурой.
          </div>

          <Cards>
            <Card
              type={1}
              pict={pict1}
              desc={'Наша распределенная команда работает с 2008 года. Нам доверяют: аналитику, разработку, инфраструктуру, проектирование интерфейсов, дизайн и эксплуатацию.'}
            />
            <Card
              type={1}
              pict={pict2}
              desc={'Мы знаем как организовать разработку комфортно и эффективно. У нас есть офисы для работы и встреч в Москве и Воронеже, а также офисы продаж в Сан-Франциско и Берлине.'}
            />
            <Card
              type={1}
              pict={pict3}
              desc={'Мы становимся технической командой проекта целиком или консультируем и расширяем возможности команд наших клиентов.'}
            />
          </Cards>

        </div>

        <div className="block">
          <h2 className="textWrapper tW_md text-t1">
            Evrone помогает развиваться сообществу разработчиков.
          </h2>

          <Cards type={1}>
            <Card
              type={2}
              desc={'Мы делаем самую большую в России конференцию по Ruby — RubyRussia, проводим технические митапы, делаем свой новостной дайджест, а также спонсируем и помогаем в проведении конференций по Go, Python, React/Vue и функциональным языкам.'}
            />
            <Card
              type={2}
              desc={'Наши инженеры выступают на ведущих конференциях России и Европы и вносят вклад в Open Source проекты, мы берем интервью у лидеров комьюнити и пишем статьи на самые горячие темы индустрии. Мы выступаем на конференциях Highload и TeamLeadConf и спонсируем их.'}
            />
          </Cards>

          <MiddleSlider version={'ru'}/>
        </div>

        <div className="block">
          <h3 className="textWrapper tW_bg text-t2">
            Чтобы работа была в кайф, мы придумали Evrone Benefits:  личный бюджет, пополняемый каждый год работы в Evrone. Его можно потратить на всё. Ну, почти)
          </h3>

          <Cards type={0}>
            <Card
              type={0}
              pict={pict4}
              title={'Забота о здоровье'}
              desc={'Мы хотим, чтобы вы выбрали тот тип добровольного медицинского страхования, который подходит вам больше всего и лучше всего работает в вашем городе. Расходы на ваш персональный ДМС можно компенсировать из Evrone Benefits.'}
            />
            <Card
              type={0}
              pict={pict5}
              title={'Спорт и инвентарь'}
              desc={'Для многих из нас занятие спортом — важная часть жизни и способ поддержания хорошего здоровья. Наша команда будет рада помочь с покупкой абонемента или инвентаря для спорта.'}
            />
            <Card
              type={0}
              pict={pict6}
              title={'Языковые курсы'}
              desc={'Каждый предпочитает разные школы, а кто-то персональные занятия с преподавателем. Как бы там ни было, ваши затраты на изучения иностранного языка можно компенсировать из Evrone Benefits.'}
            />
            <Card
              type={0}
              pict={pict7}
              title={'Техника для работы'}
              desc={'Мы распределенная команда, многие из нас часто путешествуют и перемещаются. Поэтому стоимость компьютера/ноутбука (полную или частичную) или стоимость другой необходимой для работы техники можно компенсировать из Evrone Benefits.'}
            />
            <Card
              type={0}
              pict={pict8}
              title={'Образование'}
              desc={'Вы можете выбрать любой необходимый вам курс, мастер-класс, семинар, коучинг или обучение в любом виде и компенсировать стоимость из Evrone Benefits. Также можно компенсировать стоимость билета и расходы на поездку на конференцию.'}
            />
          </Cards>

        </div>
        <div className="block">
          <h4 className="textWrapper tW_md text-t1">
            Мы дорожим нашей культурой и инженерным духом.
          </h4>

          <Cards type={1}>
            <Card
              type={3}
              pict={c2020}
              videoUrl={'//youtube.com/embed/0tGPuFIwMvQ?rel=0&fmt=18&html5=1&showinfo=0'}
              title={'Evrone 2020'}
              desc={'По количеству громких событий в мире 2020 год переплюнул, кажется, всё, что было до этого. Такой год заслужил отдельный фильм о том, что пережила наша команда, как справилась с трудностями и изменилась навсегда. Мы спасали коллег из других стран, помогали клиентам не потерять бизнес, защищались от ddos-атак, искали способ стать ближе друг к другу и росли, несмотря ни на что. Познакомьтесь с нашими историями и узнайте, как мы проводим мероприятия, решаем клиентские задачи и расширяем свою экспертизу в цифровых проектах.'}
            />
            <Card
              type={3}
              pict={c2019}
              videoUrl={'//youtube.com/embed/tot1vgpr2qM?rel=0&fmt=18&html5=1&showinfo=0'}
              title={'Evrone 2019'}
              desc={'Документальный фильм об Evrone, снятый к 10-летию компании. Мы сняли видео, в котором рассказали как появилась компания, как развивалась и к чему мы пришли за годы существования Evrone. В фильме вы узнаете про то, как благодаря традициям, мы сохраняем некую семейную атмосферу, оставаясь распределенной командой. Как мы развиваем IT сообщество и делаем крутые мероприятия. И конечно же, как собираем крутую экспертную команду для реализации бизнес идей наших клиентов!'}
            />
          </Cards>

        </div>

      </Layout>
    )
  } else {
    return (
      <Layout>
        <Seo
          lang={'en'}
          title="Evrone is looking for talents!"
          description={"We are looking for Ruby, Python, Go and frontend developers. See our job postings and join us!"}
        />

        <div className={"block"}>
          <h1 className="textWrapper tW_bg text-t1">
            The Evrone team is engaged in product development for startups and assists with the digital transformation of businesses in the US and Europe.
          </h1>

          <div className={"textWrapper"}>
            <BigSlider />
          </div>

          <div className={"textWrapper tW_bg text-t2"}>
            We work on technically-complex, high-load, science-intensive projects those with extensive business logic or large server infrastructures.
          </div>

          <Cards>
            <Card
              type={1}
              pict={pict1}
              title={'Our entire team has been working remotely since 2008.'}
              desc={'Our entire team has been working remotely since 2008. Our clients trust us with: analytics, Interface and graphic design, development, infrastructure and operations.'}
            />
            <Card
              type={1}
              pict={pict2}
              title={'We know how to organize distributed development comfortably and efficiently.'}
              desc={'We know how to organize distributed development comfortably and efficiently. We have offices for work and meetings, as well as sales offices in San Francisco and Berlin.'}
            />
            <Card
              type={1}
              pict={pict3}
              title={'We can serve as the technical team of the project.'}
              desc={'We can serve as the technical team of the project, as a whole, or we can consult with and empower the existing teams of our clients.'}
            />
          </Cards>

        </div>

        <div className="block">
          <h2 className="textWrapper tW_md text-t1">
            Evrone helps the development community grow.
          </h2>

          <Cards type={1}>
            <Card
              type={2}
              desc={'Our engineers speak at leading conferences in Europe and contribute to open-source projects. We also interview community leaders and write articles on the hottest topics in the industry. We are sponsoring and spoke at the Highload and TeamLeadConf conferences.'}
            />
            <Card
              type={2}
              desc={'We organizing the largest Ruby conference in Eastern Europe, holding technical meetups, creating our own news digest, and sponsoring and helping to hold conferences on Go, Python, React/Vue, and functional languages.'}
            />
          </Cards>

          <MiddleSlider />
        </div>

        <div className="block">
          <h3 className="textWrapper tW_bg text-t2">
            To make work enjoyable, we introduced Evrone Benefits:
            a personal budget replenished every year of your work at Evrone. It can be spent on anything. Almost)
          </h3>

          <Cards type={0}>
            <Card
              type={0}
              pict={pict4}
              title={'Healthcare'}
              desc={'We want you to choose the type of voluntary health insurance that\'s right for you and works best in your city. The cost of your personal VHI can be reimbursed from Evrone Benefits.'}
            />
            <Card
              type={0}
              pict={pict5}
              title={'Sports and equipment'}
              desc={'For many of us, exercise is an important part of life and a way to maintain good health. So we are happy to help with the purchase of a gym membership or sports equipment.'}
            />
            <Card
              type={0}
              pict={pict6}
              title={'Language courses'}
              desc={'Everyone prefers different schools, courses, and methods of learning. No matter what your preference is, your expenses for learning a foreign language can be compensated from Evrone Benefits.'}
            />
            <Card
              type={0}
              pict={pict7}
              title={'Equipment for work'}
              desc={'We are a distributed team, so many of us travel and relocate frequently. Therefore, the cost of a computer/laptop (full or partial) or the cost of other equipment necessary for work can be compensated from Evrone Benefits.'}
            />
            <Card
              type={0}
              pict={pict8}
              title={'Education'}
              desc={'You can choose any course, master class, seminar, coaching, books, or training in any form and compensate for the cost from Evrone Benefits. You can also be reimbursed for the costs of tickets and travel to conferences.'}
            />
          </Cards>

        </div>
        <div className="block">
          <h4 className="textWrapper tW_md text-t1">
            We value our corporate culture and engineering spirit.
          </h4>

          <Cards type={1}>
            <Card
              type={3}
              pict={c2020}
              videoUrl={'//youtube.com/embed/0tGPuFIwMvQ?rel=0&fmt=18&html5=1&showinfo=0'}
              title={'Evrone 2020'}
              desc={'In terms of the number of life-changing events that happened in the world, 2020 surpassed everything that happened before. Such a year deserved a separate film about what our team went through, how we coped with difficulties, and what changed forever. We saved colleagues from other countries when flights were canceled during the pandemic, helped clients keep their businesses, defended clients against DDoS attacks, and looked for ways to become closer to each other and grow—no matter what. Watch our stories to find out how we host events, solve client problems, and expand our expertise in digital projects.'}
            />
            <Card
              type={3}
              pict={c2019}
              videoUrl={'//youtube.com/embed/tot1vgpr2qM?rel=0&fmt=18&html5=1&showinfo=0'}
              title={'Evrone 2019'}
              desc={'A documentary film about Evrone, made for the company\'s 10th anniversary. We shot a video in which we explained how the company was started, how it developed, and what it has become over the years. In the film, you will learn about how, thanks to our culture and traditions, we maintain a kind of family atmosphere, while remaining a distributed team. We also talk about how we help develop the IT community and make cool events. And of course, we discuss how we assembled an awesome expert team to implement the business ideas of our clients!'}
            />
          </Cards>

        </div>

      </Layout>
    )
  }
}

export default IndexPage
