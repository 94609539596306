import * as React from "react"

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import * as styles from "./middleSlider.module.scss"

// import required modules
import { Navigation } from "swiper";
import {StaticImage} from "gatsby-plugin-image";
import VideoFrame from "../videoFrame/videoFrame";

import v_ak from '../../images/video_covers/video-covers-ak.jpg';
import v_al from '../../images/video_covers/video-covers-al.jpg';
import v_am from '../../images/video_covers/video-covers-am.jpg';
import v_dk from '../../images/video_covers/video-covers-dk.jpg';
import v_gp from '../../images/video_covers/video-covers-gp.jpg';
import v_is from '../../images/video_covers/video-covers-is.jpg';
import v_pg from '../../images/video_covers/video-covers-pg.jpg';


let listYoutube = [
  {
    src: "https://www.youtube.com/embed/aOZTJifiRoU",
    link: "https://www.youtube.com/watch?v=aOZTJifiRoU",
    title: 'Григорий Петров на Frontend meetup',
    titleEn: 'Grigory Petrov on Frontend meetup',
    light: v_gp
  },
  {
    src: "https://www.youtube.com/embed/al9PTWHy1-I",
    link: "https://www.youtube.com/watch?v=al9PTWHy1-I",
    title: 'Саша Кириллов на HighLoad 2021',
    titleEn: 'Alexander Kirillov on HighLoad++ Foundation',
    light: v_ak
  },
  {
    src: "https://www.youtube.com/embed/ayxIj_Bcvm4",
    link: "https://www.youtube.com/watch?v=ayxIj_Bcvm4",
    title: 'Алексей Лихачев на TeamLeadConf',
    titleEn: 'Alexey Likhachev on TeamLeadConf',
    light: v_al
  },
  {
    src: "https://www.youtube.com/embed/h-i1hXv22xA",
    link: "https://www.youtube.com/watch?v=h-i1hXv22xA",
    title: 'Дмитрий Карпунин на MetaConf',
    titleEn: 'Dmitry Karpunin on MetaConf',
    light: v_dk
  },
  {
    src: "https://www.youtube.com/embed/McR7Tb2EJOo",
    link: "https://www.youtube.com/watch?v=McR7Tb2EJOo",
    title: 'Иван Соловьёв на Frontend meetup',
    titleEn: 'Ivan Solovyov on Frontend meetup',
    light: v_is
  },
  {
    src: "https://www.youtube.com/embed/FKOkbjNJBqE",
    link: "https://www.youtube.com/watch?v=FKOkbjNJBqE",
    title: 'Алексей Мацкевич на Ruby Russia Online',
    titleEn: 'Alexey Matskevich on Ruby Russia Online',
    light: v_am
  },
  {
    src: "https://www.youtube.com/embed/U5DC_BVSO3M",
    link: "https://www.youtube.com/watch?v=U5DC_BVSO3M",
    title: 'Павел Грязнов на Go meetup',
    titleEn: 'Pavel Gryaznov on Go meetup',
    light: v_pg
  },

]

const MiddleSlider = ({version='en'})=> {
  return (
    <div className={styles.wrapMiddleSlide}>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        className={styles.middleSlider}
        slidesPerView={"auto"}
        spaceBetween={16}

        breakpoints = {{
          480: {
            spaceBetween: 24
          },
          720: {
            spaceBetween: 32
          },
          960: {
            spaceBetween: 40
          }
        }}
      >

        {listYoutube.map((item,idx)=>{
          return (
            <SwiperSlide key={idx}>
              <CardSlider
                title={version === 'ru' ? item.title : item.titleEn}
                lightUrl={item.light}
                videoUrl={item.src}
                link={item.link}

                version={version}
              />
            </SwiperSlide>
          );
        })}

      </Swiper>
    </div>
  );
}

const CardSlider = ({version, title, link, lightUrl,videoUrl}) => {
  return (
    <div className={styles.cardSlider}>
      <div className="wrapImage">
        {/*<StaticImage src={'../../images/locations/moscow.jpeg'} alt={'ud'} />*/}
        <VideoFrame lightUrl={lightUrl} videoUrl={videoUrl}/>
      </div>
      <div className={`${styles.wrapText} text-t4`}>
        <div className="title">{title}</div>
        <a className={styles.link} href={link} target={'_blank'} rel={'nofollow noreferrer'}>{version === 'ru' ? 'Смотреть на YouTube ↗' : 'Watch on YouTube ↗'}</a>
      </div>
    </div>
  )
}

export default MiddleSlider